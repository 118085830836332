import React from 'react'

import { SvgIcon, SvgIconLargeViewBox } from '@guiker/react-framework'

export const icons = {
  investment: (
    <SvgIconLargeViewBox>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3 11.2307V21.3827C3.00005 27.9513 7.0453 33.8417 13.176 36.2L14.564 36.7334C15.4883 37.0889 16.5117 37.0889 17.436 36.7334L18.824 36.2C24.9547 33.8417 28.9999 27.9513 29 21.3827V11.2307C29.0052 10.4503 28.5522 9.73952 27.8427 9.41469C24.1091 7.78508 20.0735 6.96224 16 7.00002C11.9265 6.96224 7.89088 7.78508 4.15733 9.41469C3.44779 9.73952 2.99478 10.4503 3 11.2307Z'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.9333 21C22.3997 24.697 19.0573 27.3237 15.3389 26.9682C11.6205 26.6127 8.83637 23.4002 9.01296 19.6691C9.18955 15.9379 12.2647 13.0027 16 13'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23 14.7494L16.6187 21.1307C16.2767 21.4718 15.7233 21.4718 15.3813 21.1307L13.3813 19.1241'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIconLargeViewBox>
  ),
  ecosystem: (
    <SvgIcon width={32} height={32} viewBox={'0 0 32 32'}>
      <path
        d='M24 5.42137C20.0832 2.36343 14.7658 1.80581 10.2999 3.98464C5.83387 6.16347 3.00062 10.6976 3 15.6667V18.6667'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8 25.9187C11.918 28.9763 17.2363 29.5328 21.7024 27.3525C26.1685 25.1722 29.0009 20.6366 29 15.6667V12.6667'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1 16.6667L3 18.6667L5 16.6667'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M31 14.6667L29 12.6667L27 14.6667'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20 17.6667V20.6667C20 21.219 19.5523 21.6667 19 21.6667H13C12.4477 21.6667 12 21.219 12 20.6667V17.6667'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10 14.6667L16 9.66675L22 14.6667'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  ),
  trusted: (
    <SvgIcon width={32} height={32} viewBox={'0 0 32 32'}>
      <path
        d='M27.0533 19.1199L20.1733 20.4345'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.2213 11.3039L14.5973 12.8319C13.8566 13.0758 13.0508 12.7253 12.724 12.0173C12.3703 11.2583 12.6824 10.3557 13.4293 9.97727L17.908 7.72527C18.6796 7.33614 19.5779 7.28401 20.3893 7.58127L27.02 10.0066'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.91333 19.1305H8.17333L12.856 24.3785C13.3444 24.9444 14.1331 25.1471 14.8338 24.8868C15.5345 24.6265 15.9996 23.958 16 23.2105V22.3905L16.2333 22.4838C16.9135 22.7559 17.6842 22.6729 18.2909 22.2623C18.8975 21.8517 19.2611 21.167 19.2613 20.4345H19.9133C20.6542 20.434 21.3313 20.0152 21.6627 19.3525C21.994 18.6898 21.9228 17.8969 21.4787 17.3038L17.2933 11.9412'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.1107 9.63325L13.6333 9.23991C13.1523 8.88572 12.5707 8.69464 11.9733 8.69458C11.6174 8.69445 11.2648 8.7628 10.9347 8.89591L5 11.2692'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1 8.99991H3.66667C4.37155 8.97053 4.96758 9.51649 5 10.2212V18.7772C4.96757 19.4822 4.37178 20.0285 3.66667 19.9999H1'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M31 19.9999H28.3333C27.6282 20.0285 27.0324 19.4822 27 18.7772V10.2212C27.0324 9.51649 27.6284 8.97053 28.3333 8.99991H31'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16 22.3907L14.696 21.0867'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.2613 20.4346L17.304 18.4773'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  ),
}
