import React from 'react'

import { AppLayout, makeStyles } from '@guiker/react-framework'

const useStyle = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'Page' },
)

export const Page: React.FC<React.PropsWithChildren> = ({ children }) => {
  const classes = useStyle()

  return (
    <AppLayout className={classes.root} appBar={<></>} footer={<></>}>
      {children}
    </AppLayout>
  )
}
