import React, { useEffect } from 'react'

import { useLayoutContext } from '@guiker/react-framework'
import { Route, Routes } from '@guiker/router'

import { Home } from '../screens'

export const routes = {
  home: {
    exact: false,
    path: '/',
    requireAuthentication: false,
    authenticationMethod: 'user',
    Component: Home,
  },
} as const

export const Router: React.FC<{}> = () => {
  const { setAppBarIsDisplayed, setFooterIsDisplayed } = useLayoutContext()

  useEffect(() => {
    setAppBarIsDisplayed(false)
    setFooterIsDisplayed(false)
  }, [])

  return (
    <Routes>
      <Route path='*' Component={Home} />
    </Routes>
  )
}
