import React from 'react'

import {
  Display1,
  Display2,
  Display3,
  DisplayBodyBig,
  Flex,
  H4,
  isAtMostTablette,
  isMobile,
  makeStyles,
  padding,
  PBig,
  RouterLink,
  SecondaryButton,
  StaticPageContents,
  theme,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { icons } from './icons'

const useStyles = makeStyles((theme) => {
  const common = {
    padding: padding(10, 8),
    [isMobile]: {
      padding: padding(10, 3),
    },
  }

  return {
    hero: {
      ...common,
      backgroundColor: theme.palette.background.secondary,
      height: '100vh',
      maxHeight: 1080,
    },
    flexStart: {
      alignItems: 'flex-start',
    },
    icon: {
      display: 'flex',
      alignItems: 'flex-end',
      height: theme.typography.variants.display3.lineHeight,
      [isMobile]: {
        height: theme.typography.variants.display4.lineHeight,
      },
    },
    section1: {
      ...common,
    },
    section1bis: {
      alignSelf: 'center',
      padding: padding(15, 8),
      gap: theme.spacing(10),
      flexWrap: 'wrap',
      [isAtMostTablette]: {
        gap: theme.spacing(3),
      },
      [isMobile]: {
        padding: padding(15, 3),
      },
    },
    section2: {
      padding: padding(15, 8),
      gap: theme.spacing(10),
      flexWrap: 'wrap',
      [isAtMostTablette]: {
        gap: theme.spacing(3),
      },
      backgroundColor: theme.palette.background.secondary,
      [isMobile]: {
        padding: padding(15, 3),
      },
    },
    section3: {
      ...common,
      backgroundColor: '#F1F3F1',
    },
    section4: {
      ...common,
    },
    section5: {
      ...common,
      backgroundColor: theme.palette.common.black,
    },
  }
})

export const Home: React.FC = () => {
  const classes = useStyles()
  const { getAsset } = useStaticAssetContext()

  const investorsImages = getAsset('investors')

  return (
    <Flex fullWidth flexDirection='column'>
      <Flex id='willow-home-hero' fullWidth className={classes.hero}>
        <Flex flexDirection='column' gap={7} justifyContent='center' alignItems='center' fullWidth>
          <Display1 color='textSecondary' textAlign='center'>
            Guiker has acquired Willow
          </Display1>
          <Flex maxWidth={725} justifyContent='center'>
            <Display3 color='textSecondary' textAlign='center'>
              Transforming rental activity <br /> into investment opportunities for all
            </Display3>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={classes.section1bis}>
        <Flex maxWidth={865} flex='3 1 600px' flexDirection='column' gap={4}>
          <H4>
            Willow RET Holdings Inc. and its wholly owned subsidiaries (Willow) have been acquired by Solutions Guiker
            Inc. (Guiker).
          </H4>
          <DisplayBodyBig mb={0}>
            If you are a Willow accountholder, you should have received an email with a link providing access to your
            Willow account through Guiker’s website.
          </DisplayBodyBig>
          <DisplayBodyBig mb={0}>
            If you did not receive an link, email <a href='mailto:invest@guiker.com'>invest@guiker.com</a> or call{' '}
            <a href='tel:+18552240999'>+1 (855) 224-0999</a> to receive access to your Willow account.
          </DisplayBodyBig>
          <DisplayBodyBig mb={0}>
            Your investments in Willow Limited Partnership have not changed, and you will continue to be serviced by
            Willow RET Financial Services Inc., a registered exempt market dealer under the securities laws of all
            provinces and territories of Canada.
          </DisplayBodyBig>
        </Flex>
      </Flex>
      <Flex className={classes.section2}>
        <Display3 color='textSecondary'>What is Guiker</Display3>
        <Flex flex='3 1 600px' flexDirection='column' gap={4}>
          <DisplayBodyBig mb={0} color='textSecondary'>
            Founded 7 years ago, Guiker's core rental platform streamlines the leasing process by connecting property
            investors with quality tenants. Its technology-driven approach reduces vacancy rates and optimizes rental
            efficiency.
          </DisplayBodyBig>
          <DisplayBodyBig mb={0} color='textSecondary'>
            With the acquisition of Willow, Guiker will transform rental activity into investment opportunities, driving
            up ROI as its user base continues to expand.
          </DisplayBodyBig>
          <DisplayBodyBig mb={0} color='textSecondary'>
            Guiker's mission is to pave a new path in real estate, where anyone can invest with ease and rent with
            freedom, all within its synergistic ecosystem
          </DisplayBodyBig>
        </Flex>
        <Flex flexDirection='column' alignItems='center' mt={6}>
          <RouterLink isExternalLink to='https://guiker.com/'>
            <SecondaryButton size='large'>Learn more about Guiker</SecondaryButton>
          </RouterLink>
        </Flex>
      </Flex>
      <Flex fullWidth className={classes.section1} justifyContent='center' alignItems='center'>
        <Flex maxWidth={865}>
          <Display2 textAlign='center'>Discover more opportunities, unlock higher returns</Display2>
        </Flex>
      </Flex>
      <Flex fullWidth className={classes.section3} justifyContent='center' alignItems='center'>
        <Flex
          maxWidth={theme.dimensions.pageLayout.maxWidth.desktop}
          flexWrap='wrap'
          justifyContent='space-evenly'
          gap={4}
        >
          <Flex flexDirection='column' gap={0}>
            <Display2 mb={0} textAlign='center'>
              500+
            </Display2>
            <PBig mb={0} textAlign='center'>
              Individual landlords
            </PBig>
          </Flex>
          <Flex flexDirection='column' gap={0}>
            <Display2 mb={0} textAlign='center'>
              1,000+
            </Display2>
            <PBig mb={0} textAlign='center'>
              Paying tenants
            </PBig>
          </Flex>
          <Flex flexDirection='column' gap={0}>
            <Display2 mb={0} textAlign='center'>
              $125M
            </Display2>
            <PBig mb={0} textAlign='center'>
              Assets under management
            </PBig>
          </Flex>
        </Flex>
      </Flex>
      <Flex fullWidth className={classes.section4} justifyContent='center' alignItems='center'>
        <Flex maxWidth={1440} flexWrap='wrap'>
          <StaticPageContents
            items={[
              {
                adornment: <div className={classes.icon}>{icons.investment}</div>,
                asset: investorsImages.section.section1.url,
                title: 'Opportunities for any real estate investor',
                content:
                  'Guiker is the only platform where you can invest in both traditional full-ownership and PropSharing opportunities. Mix investment types and locations to create a diversified real estate portfolio protected against local market ups and downs.',
              },
              {
                adornment: <div className={classes.icon}>{icons.trusted}</div>,
                asset: investorsImages.section.section2.url,
                title: 'Trusted and regulated partner',
                content:
                  "Guiker's established reputation and regulatory compliance means you can invest with confidence, wherever you are in your property investment journey. Returning Willow investor portfolios have been securely transferred onto Guiker to ensure their continuity. Current investors will receive an email with a link to access their account.",
              },
              {
                adornment: <div className={classes.icon}>{icons.ecosystem}</div>,
                asset: investorsImages.section.section3.url,
                title: 'Invest and rent, all in one place',
                content:
                  "Guiker's complete real estate ecosystem combines rentals, property management, and investments, making it easy to find your next home while you build wealth through investment.",
              },
            ]}
          />
        </Flex>
      </Flex>
      <Flex fullWidth className={classes.section5} justifyContent='center' alignItems='center'>
        <Flex maxWidth={865} flexDirection='column' gap={8} alignItems='center'>
          <Display2 textAlign='center' color='textSecondary'>
            Continue your investment journey with Guiker
          </Display2>
          <div>
            <RouterLink isExternalLink to='https://guiker.com'>
              <SecondaryButton size='large'>Join us now</SecondaryButton>
            </RouterLink>
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}
